import { mergeAll } from 'ramda';
import {
  applyMiddleware,
  bindActionCreators,
  createStore,
  compose,
  combineReducers
} from 'redux';
import { createLogger } from 'redux-logger';
import {
  persistStore,
  PersistConfig,
  persistReducer
} from 'redux-persist';

import { Inject, Injectable, Optional, Injector } from '@angular/core';

import { IReduxConfig } from './IReduxConfig';
import {
  REDUX_ENHANCERS,
  REDUX_MODULE_CONFIG, REDUX_MODULE_PERSISTED_REDUCERS, REDUX_MODULE_REDUCERS,
  REDUX_PERSIST_CONFIG
} from './reduxStore.config';
import { watch } from './@types/redux-watch';

const firstLevelStatePrefix = 'state_';

let isReduxLoggerEnabled = true;

export function configureReduxLogger(enabled: boolean) {
  isReduxLoggerEnabled = enabled;
}

function persistReducers(reducers: Array<any>, globalPersistorConfig: PersistConfig<any>) {
  reducers.forEach(r => {
    Object.keys(r).forEach(key => {
      if (key.indexOf(firstLevelStatePrefix) !== 0) {
        // wrap reducer by persistReducer
        // create persistorConfig for reducer
        const reducerPersistorConfig = {
          ...globalPersistorConfig,
          key: `${globalPersistorConfig.key}_${key}`
        };

        const usePersistence = globalPersistorConfig.whitelist.indexOf(key) !== -1;

        // combineReducers must be used (immutable state is not supported as root of persisted reducer)
        r[firstLevelStatePrefix + key] = usePersistence ? persistReducer(reducerPersistorConfig, combineReducers(r)) : combineReducers(r);
        delete r[key];
      }
    });
  });
}


function mergeReducers(reducersList: Array<any>) {
  return mergeAll(reducersList);
}

// @dynamic
@Injectable({providedIn: 'root'})
export class ReduxStore {
  store: any;
  initialized: boolean;

  private _reducers: Array<any>;

  private static getReducersKeys(reducers: Array<any>): Array<string> {
    return reducers.map((r) => {
      for (const key in r) {
        if (r.hasOwnProperty(key)) {
          return key;
        }
      }
    }).filter(key => key);
  }

  private static getRegisteredReducers(injector: Injector) {
    const reducers = injector.get(REDUX_MODULE_REDUCERS);
    return [].concat(...reducers);
  }

  constructor(
    injector: Injector,
    @Inject(REDUX_MODULE_REDUCERS) reducersProviders: Array<Array<any>>,
    @Inject(REDUX_MODULE_CONFIG) reduxModuleConfig: IReduxConfig,
    @Inject(REDUX_PERSIST_CONFIG) private _persistorConfig: PersistConfig<any>,
    @Optional() @Inject(REDUX_ENHANCERS) reduxEnhancers: Array<any>,
  ) {
    const reducers = ReduxStore.getRegisteredReducers(injector);

    this.store = this.configureReduxStore(
      reducers,
      reduxModuleConfig,
      _persistorConfig,
      reduxEnhancers || []
    );
    if (!this.store) {
      throw new Error(
        'store cannot be undefined. Make sure to pass the redux store as the only argument of the constructor.'
      );
    }
    if (this.initialized) {
      throw new Error('Only one redux store can exist per application.');
    }
    this.initialized = true;
  }

  featureAdded(featureInjector: Injector) {
    const injectedFeaturePersistedReducers = featureInjector.get(REDUX_MODULE_PERSISTED_REDUCERS);
    const featurePersistedReducers = [].concat(...injectedFeaturePersistedReducers);
    if (featurePersistedReducers) {
      this._persistorConfig.whitelist = Array.from(new Set([...this._persistorConfig.whitelist, ...featurePersistedReducers.filter(r => r)])); // add non empty reducers + distinct
    }

    const registeredReducers = ReduxStore.getRegisteredReducers(featureInjector);
    const existingReducersKeys = ReduxStore.getReducersKeys(this._reducers);
    const newRegisteredReducersKey = ReduxStore.getReducersKeys(registeredReducers).filter(key => key.indexOf(firstLevelStatePrefix) !== 0 && existingReducersKeys.indexOf(firstLevelStatePrefix + key) === -1);
    const newRegisteredReducers = registeredReducers.filter(r => newRegisteredReducersKey.indexOf(Object.keys(r)[0]) !== -1);
    if (newRegisteredReducers.length) {
      persistReducers(newRegisteredReducers, this._persistorConfig);
      this._reducers.push(...newRegisteredReducers);

      const reducers = mergeAll(this._reducers);
      // update list of reducers in store
      this.store.replaceReducer(combineReducers(reducers));
    }
  }

  getAllStates() {
    const storeStates = this.store.getState();
    const allStates: any = {};
    // each state is nested to second level using 'combineReducers' to support immutable state with redux-persist (see function persistReducers)
    for (const stateFullKey in storeStates) {
      if (storeStates.hasOwnProperty(stateFullKey)) {
        const stateKey = stateFullKey.substring(firstLevelStatePrefix.length);
        // remove first level of state that is used as persistence workaround
        allStates[stateKey] = storeStates[stateFullKey][stateKey];
      }
    }
    // cut this level
    return allStates;
  }

  getState(stateKey: string) {
    let state;
    const storeState = this.store.getState();
    if (storeState) {
      state = storeState[firstLevelStatePrefix + stateKey];
      // state is nested to second level using 'combineReducers' to support immutable state with redux-persist (see function persistReducers)
      if (state) {
        state = state[stateKey];
      }
    }
    return state;
  }

  dispatch(action: any) {
    this.store.dispatch(action);
  }

  subscribe(listener: any, storePaths: Array<string>) {
    const _this = this;

    if (storePaths) {
      const subs = storePaths.map(path => {
        const w = watch(this.store.getState, `${firstLevelStatePrefix}${path}.${path}`);
        return this.store.subscribe(w((newVal: any, oldVal: any, objectPath: any) => {
          // console.log('!!!!! %s changed from %s to %s', objectPath, oldVal, newVal)
          listener(_this.getAllStates());
        }));
      });
      return () => {
        // unsubscribe
        subs.forEach(s => s());
      };
    } else {
      return this.store.subscribe(() => {
        return listener(_this.getAllStates());
      });
    }
  }

  bound(actionCreator: any) {
    return bindActionCreators(actionCreator, this.dispatch.bind(this));
  }

  private configureReduxStore(
    reducersList: Array<any>,
    reduxConfig: IReduxConfig,
    persistorConfig: PersistConfig<any>,
    reduxEnhancers: Array<any>
  ): any {
    const initialState = {};

    let logger;
    if (reduxConfig.useLogger) {
      logger = createLogger({
        predicate: (getState, action) => isReduxLoggerEnabled,
        stateTransformer: (state: any) => {
          // TODO transform state to better/readable format
          const result: any = {};
          for (const stateProp in state) {
            if (state.hasOwnProperty(stateProp)) {
              if (state[stateProp].toJS) {
                result[stateProp] = state[stateProp].toJS();
              } else {
                result[stateProp] = state[stateProp];
              }
            }
          }
          return result;
        }
      });
    }

    const devToolsExtensionConfig = {};

    type IDevToolsExtension = (a: any) => any;

    const devToolsEnhancer: IDevToolsExtension = (window as any).__REDUX_DEVTOOLS_EXTENSION__
      ? (window as any).__REDUX_DEVTOOLS_EXTENSION__(devToolsExtensionConfig)
      : f => f; // DEV tools must not be used before other sub-components(it modifies state structure);
    let enhancers = compose(devToolsEnhancer);

    reduxEnhancers.forEach(enhancer => {
      // add enhancers provided by other modules
      enhancers = compose(
        enhancers,
        enhancer
      );
    });

    const finalCreateStore = enhancers(createStore);

    const createStoreWithMiddleware = logger
      ? applyMiddleware(logger)(finalCreateStore)
      : finalCreateStore;


    persistReducers(reducersList, persistorConfig);
    this._reducers = reducersList;
    const rootReducer = combineReducers(mergeReducers(reducersList));
    const store = createStoreWithMiddleware(
      rootReducer,
      initialState // initial state
    );

    // begin periodically persisting the store
    persistStore(store, null, () => {
      store.getState(); // get restoredState
    });

    return store;
  }
}
