import { ChangeDetectorRef, Injector, OnDestroy, OnInit } from '@angular/core';
import { Logger } from 'ngx-myia-core';
import { Unsubscribe } from 'redux';
import { ReduxStore } from './reduxStore';

export class ReduxView implements OnInit, OnDestroy {
  protected propertiesUpdated: boolean;
  protected changeDetectorRef: ChangeDetectorRef;
  protected logger: Logger;

  private _storeSubscription: Unsubscribe;
  private _attachedStore: ReduxStore;
  private _mappingStateToProps: boolean;

  // this property is used for tracking of state change

  constructor(injector: Injector) {
    this.changeDetectorRef = injector.get(ChangeDetectorRef);
    this.logger = injector.get(Logger);
  }

  public ngOnInit() {
    this.onReduxInit();
    if (this._attachedStore) {
      const sourceState = this._attachedStore.getAllStates();
      this.propertiesUpdated = false;
      if (this.mapStateToProps(sourceState)) {
        this.updateView();
      }
    }
  }

  public ngOnDestroy() {
    this.onReduxDestroy();
    this.changeDetectorRef.detach();
    this.changeDetectorRef = null;
    // un-subscribe store changes
    if (this._storeSubscription) {
      this._storeSubscription();
      this._storeSubscription = null;
    }
  }

  protected dispatch(action: any) {
    this._attachedStore.dispatch(action);
  }

  protected updateView(now?: boolean) {
    if (this.changeDetectorRef) {
      // notify angular about model change
      this.changeDetectorRef.markForCheck();
      if (now) {
        this.changeDetectorRef.detectChanges();
      }
    }
  }

  protected onReduxInit() {
    // this method should be used to init view derived from ReduxView
  }

  protected onReduxDestroy() {
    // this method should be used to destroy view derived from ReduxView
  }

  /*
   Returns true if view should be updated
   */
  protected mapStateToProps(state: any): boolean {
    return this.propertiesUpdated;
  }

  protected getPropertyFromState<T>(
    currentValue: T,
    newValue: T,
    changedCallback?: (newValue: T, oldValue: T) => void,
    noChangeCallback?: (currentValue: T) => void
  ): any {
    const changed = currentValue !== newValue;
    this.propertiesUpdated = this.propertiesUpdated || changed;
    if (changed) {
      if (changedCallback) {
        changedCallback(newValue, currentValue);
      }
    } else {
      if (noChangeCallback) {
        noChangeCallback(currentValue);
      }
    }
    return newValue;
  }

  protected attachToStore(store: ReduxStore, storePaths: Array<string>) {
    this._attachedStore = store;
    this._storeSubscription = store.subscribe((state: any) => {
      this.setState(store, state);
    }, storePaths);
    this.setState(store);
  }

  private setState(store: ReduxStore, changedState: any = null) {
    // tslint:disable-next-line:no-string-literal
    const viewName = this['baseViewName'] || this.constructor.name;
    if (this._mappingStateToProps) {
      this._mappingStateToProps = false;
      this.logger.error(
        `${viewName}: Detected state change during mapStateToProps() call !!!!`
      );
    }
    this.logger.log(
      `State of '${viewName}' ${
        changedState ? 'updated' : 'initialized'
      } from store '${store.constructor.name}'.`
    );
    const sourceState = changedState || store.getAllStates();
    this.propertiesUpdated = false;
    this._mappingStateToProps = true;
    if (this.mapStateToProps(sourceState)) {
      this.updateView();
    }
    this._mappingStateToProps = false;
  }
}
